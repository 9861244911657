<template>
  <div>
    <v-row v-if="getDonationPageType.type === 'teacher'">
      <v-col cols="12">
        <v-row>
          <v-col cols="6" sm="4" v-if="getTeacherDetail.profile_pic">
            <img
              :src="`${getTeacherDetail.profile_pic}`"
              class="imgSTA"
              width="220"
            />
          </v-col>
          <v-col cols="6" sm="4" v-else>
            <img
              src="@/assets/publicDonationPage/teacher.png"
              class="imgST"
              width="220"
            />
          </v-col>
          <v-col cols="6" sm="7">
            <div class="studentName">{{ getTeacherDetail.name }}</div>
            <div class="student_grade_teacher">
              (Grade {{ getTeacherDetail.grade }})
            </div>
            <v-col cols="10" class="px-0">
              <div
                class="student_special_message"
                id="teacherMessage"
                v-html="getTeacherDetail.message"
                style="display: inline"
              >
                <!-- {{ getTeacherDetail.message }} -->
              </div>
            </v-col>
          </v-col>
          <v-col cols="12" sm="10">
            <div style="width: 100%; border-bottom: 1px dashed #000000"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="getDonationPageType.type === 'student'" no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col cols="6" md="4" v-if="getStudentDetail.student_logo">
            <img :src="`${getStudentDetail.student_logo}`" class="imgSTA" />
            <br />
            <div
              style="
                color: #512e96;
                font-weight: 600;
                font-family: Roboto Slab;
                font-size: 19px;
                text-align: -webkit-center;
                border-radius: 6px;
                width: 190px;
              "
              class="sharedclass"
            >
              <span> You have shared</span>
              <br />

              <div class="container1">
                <img
                  style="width: 30%"
                  src="@/assets/thingsToDo/starhw.png"
                  alt="Snow"
                />
                <div class="centered-1">
                  <span style="color: #512e96">{{
                    getStudentDetail.distinct_shares
                  }}</span>
                </div>
              </div>
              <span>time(s)</span>
            </div>
            <v-col
              cols="10"
              sm="8"
              style="
                font-size: 18px;
                color: #512e96;
                font-weight: 600;
                text-align: center;
              "
              v-if="getStudentDetail.medal_eligibility == 'not eligible'"
              class="chmpionclass"
            >
              <p>
                In order to complete the challenge and win the champion
                medallion, you need to share with at least 3 contacts and earn all of the badges.
              </p>
            </v-col>
            <v-col
              cols="10"
              sm="8"
              style="
                font-size: 18px;
                color: #512e96;
                font-weight: 600;
                text-align: center;
              "
              v-else
            >
              <p>Congratulations! you have won a Light-up Champion Medallion</p>
              <img
                style="width: 40%"
                src="@/assets/thingsToDo/HWmedalwon.png"
                alt="Snow"
              />
            </v-col>
          </v-col>
          <v-col cols="6" md="4" v-else>
            <img src="@/assets/publicDonationPage/student.png" class="imgST" />
            <br />
            <div
              class="imgST sharedclass"
              style="
                color: #512e96;
                font-weight: 600;
                font-family: Roboto Slab;
                font-size: 19px;
                text-align: -webkit-center;
                margin-right: 44%;
              "
            >
              <span> You have shared</span>
              <br />

              <div class="container1">
                <img
                  style="width: 30%"
                  src="@/assets/thingsToDo/starhw.png"
                  alt="Snow"
                />
                <div class="centered-1">
                  <span style="color: #512e96">{{
                    getStudentDetail.distinct_shares
                  }}</span>
                </div>
              </div>
              <span>time(s)</span>
            </div>
            <v-col
              cols="10"
              sm="8"
              style="
                font-size: 18px;
                color: #512e96;
                font-weight: 600;
                text-align: center;
              "
              v-if="getStudentDetail.medal_eligibility == 'not eligible'"
              class="chmpionclass"
            >
              <p>
                In order to complete the challenge and win the champion
                medallion, you need to share with at least 3 contacts and earn all of the badges.
              </p>
            </v-col>
            <v-col
              cols="10"
              sm="8"
              style="
                font-size: 18px;
                color: #512e96;
                font-weight: 600;
                text-align: center;
              "
              v-else
            >
              <p>Congratulations! you have won a Light-up Champion Medallion</p>
              <img
                style="width: 40%"
                src="@/assets/thingsToDo/HWmedalwon.png"
                alt="Snow"
              />
            </v-col>
          </v-col>

          <v-col cols="6" md="7">
            <v-row class="py-0">
              <v-col cols="12" class="py-0 px-0">
                <div class="studentName">{{ getStudentDetail.name }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="$route.matched[0].path.substring(1) === 'parent'"
                class="px-0 py-0"
              >
                <div class="student_grade_teacher">
                  {{ getStudentDetail.teacher }} (Grade
                  {{ getStudentDetail.grade }})
                </div>
              </v-col>
              <v-col
                cols="2"
                class="px-0 pt-4"
                v-if="$route.matched[0].path.substring(1) !== 'parent'"
              >
                <img :src="getStudentDetail.org_logo" class="studentOrgImg" />
              </v-col>
              <v-col
                cols="10"
                v-if="$route.matched[0].path.substring(1) !== 'parent'"
              >
                <div class="student_associated_school">
                  <span
                    style="cursor: pointer"
                    @click="
                      viewPublicDonationPageHandler(getStudentDetail.school_id)
                    "
                    >{{ getStudentDetail.org_name }}</span
                  >
                </div>
                <div class="student_grade_teacher">
                  {{ getStudentDetail.teacher }} (Grade
                  {{ getStudentDetail.grade }})
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="px-0 py-0"
                v-if="this.$route.matched[0].path.substring(1) === 'parent'"
              >
                <v-btn
                  :class="
                    $vuetify.breakpoint.smAndUp
                      ? 'text-capitalize'
                      : 'text-capitalize px-2'
                  "
                  color="#F2F2F2"
                  style="border: 1px solid black; border-radius: 8px"
                  @click="editStudent"
                >
                  <v-icon left :size="$vuetify.breakpoint.xsOnly ? '12' : ''"
                    >mdi-pencil</v-icon
                  >
                  <span>Edit Student Profile</span>
                </v-btn>
                <v-btn
                  :class="
                    $vuetify.breakpoint.smAndUp
                      ? 'text-capitalize ml-1'
                      : 'text-capitalize mt-2 px-4'
                  "
                  color="#F2F2F2"
                  style="border: 1px solid black; border-radius: 8px"
                  @click="viewPublicStudentDonationPageHandler(studentDetail)"
                >
                  <span>View My Public Page</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" class="px-0">
                <div class="student_special_message" v-html="getDetailMessage">
                  <!-- {{ getStudentDetail.message }} -->
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="10">
            <div style="width: 100%; border-bottom: 1px dashed #000000"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      v-if="
        $route.matched[0].path.substring(1) === 'parent' && $route.query.student
      "
    >
      <v-col cols="12">
        <things-to-do></things-to-do>
      </v-col>
    </v-row>
    <v-row
      v-if="
        getDonationPageType.type === 'teacher' ||
        getDonationPageType.type === 'student'
      "
    >
      <v-col cols="12">
        <event-timeline></event-timeline>
      </v-col>
    </v-row>
    <v-row>
     <v-row
      v-if="
        !getDonationPageType ||
        getDonationPageType.type === 'school' ||
        getDonationPageType.type === 'teacher' ||
        (getDonationPageType.type === 'student' &&
          $route.matched[0].path.substring(1) !== 'parent')
      "
    >
      <v-col cols="4">
        <img :src="getTitle.logo" alt="" class="oraganisationLogo" />
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.smAndUp ? 'px-0 pt-12' : ''">
        <span class="headingSchool">{{ getTitle.title }}</span>
      </v-col>
    </v-row>
      <v-col cols="12">
        <campaign-message></campaign-message>
      </v-col>
    </v-row>
   
    <v-row
      v-if="
        getDonationPageType.type === 'student' &&
        $route.matched[0].path.substring(1) !== 'parent'
      "
    >
      <v-col cols="12">
        <school-progress-detail></school-progress-detail>
      </v-col>
    </v-row>

    <v-row
      v-if="
        $route.matched[0].path.substring(1) !== 'parent' &&
        (!getDonationPageType.type || getDonationPageType.type === 'school')
      "
    >
      <v-col cols="12">
        <event-timeline></event-timeline>
      </v-col>
    </v-row>
    <!--  <v-row v-if="$route.matched[0].path.substring(1) !== 'parent'">
      <v-col cols="12">
        <intro-video-pdp></intro-video-pdp>
      </v-col>
    </v-row>-->
    <!-- <v-row>
      <v-col cols="12" sm="10">
        <div class="heading">
          <div>What are we raising money for?</div>
          <div class="hLine"></div>
        </div>
        <div class="paragraphMessage">
          <div v-html="getTitle.message"></div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="10"
        v-if="
          getDonationPageType.type === 'student' &&
          $route.matched[0].path.substring(1) !== 'parent'
        "
      >
        <div style="font-size: 24px; color: #2c1963">
          ${{ schoolDetail.total_fund_raised }}
          <span
            style="
              color: #2d2d2d;
              font-size: 16px;
              margin-top: auto;
              margin-bottom: auto;
            "
            >raised of ${{ schoolDetail.school_goal }} School goal</span
          >
        </div>
        <v-progress-linear
          :value="schoolFundRaisedPercentage"
          color="#4A3290"
          height="25"
          style="border-radius: 20px"
        >
          <strong style="color: white"
            >{{ schoolFundRaisedPercentage }}%</strong
          >
        </v-progress-linear>
        <div style="font-size: 20px; width: 100%">
          {{ schoolDetail.total_donor }}
          <span style="font-size: 16px; color: #757575">donors</span>
          <div style="float: right">
            {{ getStudentDetail.days }}
            <span style="font-size: 16px; color: #757575">days left</span>
          </div>
        </div>
      </v-col>
    </v-row>-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import { API_ADMIN_GET_SCHOOL_DETAIL } from "@/constants/APIUrls";
import _ from "lodash";
import Helper from "@/helper";
export default {
  name: "CampaignInfo",
  components: {
    EventTimeline: () => import("../DonationInfoCard/EventTimeline"),
    CampaignMessage: () =>
      import(
        "@/components/PublicDonationPages/CampaignMessage/CampaignMessage"
      ),
    ThingsToDo: () => import("../ThingsToDo/ThingsToDo"),
    // IntroVideoPdp: () =>
    //   import("@/components/PublicDonationPages/IntroVideoPdp/IntroVideoPdp"),
    SchoolProgressDetail: () =>
      import("../../SchoolProgressDetail/SchoolProgressDetail"),
  },
  data() {
    return {
      schoolDetail: {},
      showeditmodal: false,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign.campaign_detail;
    },
    studentDetail() {
      return this.getCampaign.student_detail;
    },
    getDonationPageType() {
      return this.getCampaign.type;
    },
    isLoggedIn() {
      return _.isEmpty(this.userDetails) ? false : this.userDetails;
    },
    getTeacherDetail() {
      if (this.getDonationPageType.type === "teacher") {
        let teacher_name = this.getCampaign.teacher_detail.display_name;
        let grade = this.getCampaign.teacher_detail.grade_title;
        let teacher_special_message =
          this.getCampaign.teacher_detail.teacher_special_message;
        let pic = this.getCampaign.teacher_detail.profile_pic;

        return {
          name: teacher_name,
          grade: grade,
          message: teacher_special_message,
          profile_pic: pic,
        };
      } else {
        return null;
      }
    },
    getStudentDetail() {
      if (this.getDonationPageType.type === "student") {
        let student_name = this.getCampaign.student_detail.student_first_name;
        localStorage.setItem("studentfirstname", student_name);
        console.log("et student_name", student_name);
        let special_message =
          this.getCampaign.student_detail.student_special_message;
        let organization_name = this.getCampaign.student_detail.school_name;
        let organization_logo = this.getCampaign.student_detail.school_logo;
        let teacher = this.getCampaign.student_detail.grade_teacher;
        let grade = this.getCampaign.student_detail.grade_title;
        let school = this.getCampaign.student_detail.school_id;
        let campaign = this.getCampaign.campaign_detail.id;
        let days_left = this.getCampaign.campaign_detail.remaining_day;
        let pic = this.getCampaign.student_detail.student_logo;
        let distinctshare = this.getCampaign.student_detail.distinct_shares;
        let medaleligibility =
          this.getCampaign.student_detail.medal_eligibility;
        console.log("et pic", pic);
        localStorage.setItem("studentProfilepic", pic);
        return {
          name: student_name,
          org_name: organization_name,
          org_logo: organization_logo,
          message: special_message,
          teacher: teacher,
          grade: grade,
          school_id: school,
          campaign_id: campaign,
          days: days_left,
          student_logo: pic,
          distinct_shares: distinctshare,
          medal_eligibility: medaleligibility,
        };
      } else {
        return {
          name: null,
          org_name: null,
          org_logo: null,
          message: null,
          teacher: null,
          grade: null,
          school_id: null,
          campaign_id: null,
          days: null,
          student_logo: null,
        };
      }
    },
    getTitle() {
      let title = "";
      let logo = "";
      let specialMessage = "";
      if (!this.getDonationPageType) {
        title = this.getCampaignDetail.organization_name;
        logo = this.getCampaignDetail.organization_logo;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "school") {
        title = this.getCampaign.school_detail.school_name;
        logo = this.getCampaign.school_detail.logo_url;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "teacher") {
        title = this.getCampaign.teacher_detail.campaign_school;
        logo = this.getCampaign.teacher_detail.campaign_school_logo;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "student") {
        title = this.getCampaign.student_detail.school_name;
        logo = this.getCampaign.student_detail.school_logo;
        specialMessage = this.getCampaignDetail.special_message;
      }
      return { title: title, logo: logo, message: specialMessage };
    },
    schoolFundRaisedPercentage() {
      return Math.round(
        (this.schoolDetail.total_fund_raised / this.schoolDetail.school_goal) *
          100
      );
    },
    getDetailMessage() {
      return this.getCampaignDetail.teacher_incentive;
    },
  },
  methods: {
    ...mapActions({
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
    }),
    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
        //   localStorage.setItem("first_name",student_name)
        console.log("studentfirstname*****", res.data.school_detail);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getStudentDetail.school_id;
      formData["campaign_id"] = this.getStudentDetail.campaign_id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    viewPublicDonationPageHandler(school) {
      const queryParam = "school=" + school;
      Helper.openPublicDonationPage("", queryParam);
    },
    viewPublicStudentDonationPageHandler(item) {
      const { id } = item;
      const queryParam = "student=" + id;
      const host = window.location.host;
      const protocol = window.location.protocol;
      const href = protocol + "//" + host + "/?" + queryParam;
      window.open(href);
    },
    editStudent() {
      this.toggleCreateStudentModal({
        show: true,
        actionType: "edit",
        id: this.$route.query.student,
      });
    },
  },
  mounted() {
    this.getSchoolDetail();
    // console.log(this.getCampaign, "student");
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
#teacherMessage >>> p {
  display: inline;
}
.headingSchool {
  font-size: 27px;
  font-weight: 700;
  font-family: Roboto Slab;
}
.paragraphMessage {
  font-family: Lato;
  font-size: 15px;
}
.studentName {
  font-size: 40px;
  font-family: Roboto Slab;
  color: #2c1963;
  font-weight: 600;
}
.student_associated_school {
  font-size: 24px;
  color: #2d2d2d;
  display: flex;
}
.student_grade_teacher {
  color: #747474;
  font-size: 20px;
}
.student_special_message {
  font-size: 17px;
}

.imgST {
  border-radius: 6px;
  min-height: 160px;
  width: 220px;
  background: transparent;
}
.imgSTA {
  box-shadow: 2px 4px 26px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 220px;
}
.studentOrgImg {
  width: 60px;
  height: 55px;
}
.oraganisationLogo {
  width: 38%;
}
.sharedclass {
  margin-left: 10px;
}
.chmpionclass {
  margin-left: -17px;
}
@media only screen and (min-width:550px) and (max-width: 767px) {
  .chmpionclass {
    margin-left: 40px; /* Adjust this value as needed */
  }
 
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .chmpionclass {
    margin-left: 27px; /* Adjust this value as needed */
  }
 
}
@media only screen and (min-width: 1024px) and (max-width: 1900px){
  .chmpionclass {
    margin-left: 24px; /* Adjust this value as needed */
  }
 
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sharedclass {
    margin-left: -25px;
  }
  .chmpionclass {
  margin-left: 3px;
}
  .imgST {
    width: 90%;
    min-height: 100px;
    margin: auto;
  }
  .imgSTA {
    box-shadow: 2px 4px 26px 6px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    width: 90%;
    min-height: 140px;
  }
  .v-btn >>> span {
    font-size: 12px;
  }
  .headingSchool {
    font-size: 23px;
  }
  .studentName {
    font-size: 23px;
    padding-top: 10px;
  }
  .studentOrgImg {
    width: 30px;
    height: 30px;
  }
  .student_associated_school {
    font-size: 15px;
  }
  .student_grade_teacher {
    font-size: 13px;
  }
  .student_special_message {
    font-size: 11px;
  }
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    float: right;
  }
  .circle {
    width: 100px;
    height: 100px;
    background-color: #3498db;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@400;500;600&display=swap");

.container1 {
  position: relative;
  text-align: center;
  color: black;
}

.centered-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: Montserrat;
  font-size: 16px;
  color: #ffb85a !important;
  transform: translate(-50%, -50%);
}
</style>
